/* eslint-disable no-underscore-dangle */
import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import useState from './state';
import useDarkQuery from './useDarkQuery';

export const StateContext = createContext();
export const useStore = () => useContext(StateContext);

export const StateProvider = ({ children }) => {
  // Use state.
  const [state, actions] = useState();
  const value = useMemo(() => [state, actions], [state, actions]);

  // Dispatch the theme when the query changes.
  useDarkQuery(actions.setTheme);

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
