import { useState } from 'react';
import useTheme from './useTheme';

export default () => {
  const [theme, setTheme] = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [scrollPos, setScrollPos] = useState({ y: 1, height: 10 });

  const state = {
    theme,
    expanded,
    scrollPos,
    overlay,
  };

  const actions = {
    setTheme,
    setExpanded,
    setScrollPos,
    setOverlay,
  };

  return [state, actions];
};
