/* eslint-disable */
import React from 'react';
import { StateProvider } from './src/context';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/files/style/index.scss';

export const wrapRootElement = ({ element }) => (
  <StateProvider>{element}</StateProvider>
);
