import { useEffect } from 'react';

export default (setTheme) => {
  const query = '(prefers-color-scheme: dark)';

  useEffect(() => {
    const darkQuery = window.matchMedia(query);
    const updateMatch = () => {
      const theme = darkQuery.matches ? 'dark' : 'light';
      setTheme(theme);
    };

    darkQuery.addListener(updateMatch);

    return () => {
      darkQuery.removeListener(updateMatch);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
