import { useState, useCallback } from 'react';

export default () => {
  const initialTheme = typeof window !== 'undefined' ? window.__theme : 'light'; // eslint-disable-line

  const [theme, setTheme] = useState(initialTheme !== null ? initialTheme : 'light');

  const setValue = useCallback((value) => {
    // Try to set it in the local storage.
    try {
      window.__theme = value; // eslint-disable-line
      localStorage.setItem('theme', value);
    } catch (err) {} // eslint-disable-line

    setTheme(value);
  }, []);

  return [theme, setValue];
};
